body {
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-name: fadeIn;
  animation-duration: 1s;
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  padding-top: 110px;
}

* {
  outline: none;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.marg-top {
  margin-top: 60px;
}

.marg-top-big {
  margin-top: 100px;
}

.marg-bottom {
  margin-bottom: 60px;
}

.absolute-link {
  position: relative;
}

.absolute-link .absolute-link__item {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
}

.position-relative {
  position: relative;
}

.section-heading {
  font-size: 42px;
  line-height: 52px;
  font-weight: 500;
  color: #1D70B7;
}

.page-heading {
  font-size: 36px;
  line-height: 48px;
  font-weight: 400;
  color: #000;
  margin-bottom: 60px;
}

.archive-list-item {
  margin: 20px 0;
  padding: 30px;
  border-bottom: 1px dotted #eaeaea;
  text-align: center;
}

.archive-list-item img {
  width: auto;
  max-height: 300px;
}

.archive-list-heading {
  font-size: 22px;
  margin: 10px 0;
  font-weight: 400;
  text-align: center;
  color: #1D70B7;
}

.custom-heading {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 42px;
  line-height: 46px;
  color: #000;
  font-weight: 500;
}

.custom-heading .custom-heading__top {
  padding-left: 2px;
  display: inline-block;
}

.custom-heading .custom-heading__bottom {
  display: inline-block;
  color: #fff;
  background-color: #1D70B5;
  padding: 12px 25px;
  margin-top: 15px;
  border-radius: 15px;
  text-align: left;
}

.cbtn {
  padding: 12px 35px;
  display: inline-block;
  position: relative;
  color: #fff;
  font-weight: 500;
  box-shadow: 0px 10px 20px #1D70B763;
  border-radius: 28px;
  background: linear-gradient(262deg, #82c5ff 0%, #1d70b7 100%);
}

.cbtn:hover {
  box-shadow: 0 0 6px 4px #4d9ee5;
  letter-spacing: 1px;
}

.cbtn--icon {
  padding-right: 75px;
  padding-left: 50px;
}

.cbtn__icon {
  box-shadow: 0px 3px 6px #1D70B75E;
  background: linear-gradient(180deg, #6AB5F5 0%, #1D70B7 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  bottom: 0;
  right: -5px;
  transition: 0.3s;
}

.cbtn:hover .cbtn__icon {
  box-shadow: 0 0 6px 4px #4d9ee5;
}

.cbtn__icon svg {
  margin-left: 4px;
}

.cbtn-underline {
  display: inline-block;
  color: #1D70B7;
  font-weight: 500;
  text-decoration: underline;
}

.cbtn-underline:hover {
  letter-spacing: 2px;
}

.text-center {
  text-align: center;
}

.wp-block-file a:hover {
  color: #1D70B5;
}

/* @ @ HEADER @ @ */
/* menu button start */
.nav-icon {
  width: 23px;
  height: 23px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 999999;
  position: relative;
  display: none;
  margin-left: 20px;
}

.nav-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #1D70B7;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.nav-icon span:nth-child(1) {
  top: 0px;
}

.nav-icon span:nth-child(2),
.nav-icon span:nth-child(3) {
  top: 10px;
}

.nav-icon span:nth-child(4) {
  top: 20px;
}

.nav-icon.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.nav-icon.open span:nth-child(2) {
  transform: rotate(45deg);
}

.nav-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.nav-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

/* menu button end */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 3px 6px rgba(183, 183, 183, 0.1);
  transition: 0.3s;
  z-index: 9999;
  background-color: #fff;
}

.scrolled .header {
  padding-top: 10px;
  padding-bottom: 10px;
}

.header__logo {
  width: 300px;
  padding-right: 30px;
  padding-left: 60px;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.scrolled .header__logo {
  padding-left: 15px;
}

.header__logo img {
  width: 255px;
  transition: 0.3s;
}

.scrolled .header__logo img {
  width: 155px;
}

.header__cart {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 180px;
}

.header__cart > a > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__cart a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px 8px 25px;
  border: 1px solid #1D70B7;
  border-radius: 18px;
  transition: 0.3s;
}

.header__cart a:hover {
  background-color: #1D70B7;
  color: #fff;
}

#header__counter {
  position: relative;
  display: inline-block;
  margin-right: 15px;
}

#header__counter span {
  position: absolute;
  top: -15px;
  right: -5px;
  font-size: 12px;
  font-weight: 600;
  color: #1D70B7;
  transition: 0.3s;
}

.header__cart a:hover #header__counter span {
  color: #fff;
}

#header__counter svg path {
  transition: 0.3s;
}

.header__cart a:hover #header__counter svg path {
  fill: #fff;
}

#header__price {
  font-size: 16px;
}

/* menu start */
.header__menu {
  display: flex;
  align-items: center;
  flex: 1;
}

.header__menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header__menu > div {
  width: 100%;
}

.header__menu > div > ul {
  display: flex;
  justify-content: space-between;
}

.header__menu > div > ul > li {
  padding: 15px 22px;
  position: relative;
  cursor: pointer;
}

.header__menu > div > ul > li.menu-special {
  padding-left: 5px;
  padding-right: 5px;
}

.header__menu > div > ul > li.menu-special > a {
  background-color: #1D70B5;
  color: #fff;
  padding: 10px 15px;
  border-radius: 25px;
}

.header__menu > div > ul > li:first-child {
  padding-left: 0;
}

.header__menu > div > ul > li.menu-item-has-children:after {
  content: "";
  background-image: url(../../images/right-arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  width: 14px;
  height: 12px;
  position: absolute;
  right: 0;
  top: 23px;
  display: inline-block;
}

.header__menu > div > ul > li > a {
  font-weight: 500;
}

.header__menu > div > ul > li.menu-color > a {
  font-weight: 500;
  color: #1D70B5;
}

.header__menu > div > ul ul {
  display: none;
}

/* menu end */
.bar {
  background-color: #2A8ADD;
  padding: 10px 0;
}

.bar .row {
  justify-content: space-between;
}

.bar__left,
.bar__right {
  padding: 0 15px;
}

.bar__left,
.bar__left-item {
  display: flex;
  align-items: center;
  position: relative;
}

.bar__left-item {
  padding-right: 25px;
  margin-right: 25px;
}

.bar__left-item:not(:last-child) {
  border-right: 1px dotted #fff;
}

.bar__left-item span {
  display: block;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  margin-left: 10px;
}

.bar__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bar__right span {
  font-size: 12px;
  display: inline-block;
  margin-right: 20px;
  color: #fff;
}

.bar__right a {
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.bar__right a:not(:last-child) {
  border-right: 1px dotted #fff;
  padding-right: 15px;
}

.bar__right svg {
  height: 30px;
  width: 30px;
}

.warn__txt {
  font-size: 15px;
  line-height: 20px;
  color: #FF6F6B;
  font-weight: 500;
}

.warn__txt p {
  border-bottom: 1px solid #FF6F6B;
  padding-bottom: 15px;
  margin-top: 15px;
}

/* @ @ HEADER END @ @ */
/* @ @ BANER START @ @ */
.baner {
  position: relative;
  padding-top: 60px;
}

.baner > img {
  position: absolute;
  right: 10vw;
  top: 40px;
  z-index: -1;
}

.baner__title {
  color: #1D70B7;
  margin-bottom: 20px;
  font-size: 58px;
  line-height: 60px;
  font-weight: 500;
}

.baner__subtitle {
  display: block;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 20px;
  color: #000;
  font-weight: 500;
}

.baner__txt {
  display: flex;
  flex-wrap: wrap;
}

.baner__left,
.baner__right {
  max-width: 50%;
  flex: 0 0 50%;
  margin-bottom: 20px;
}

.baner__left {
  padding-right: 20px;
}

.baner .cbtn {
  margin-top: 20px;
}

.baner__right {
  padding-left: 20px;
}

.baner-bottom {
  position: relative;
  margin-top: 40px;
  overflow-x: clip;
}

.baner-bottom > img {
  position: absolute;
  top: -140px;
  left: 10vw;
  opacity: 0.35;
  z-index: -2;
}

.baner-bottom__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.baner-bottom__title {
  color: #1D70B7;
  margin-bottom: 20px;
  font-size: 45px;
  line-height: 50px;
  font-weight: 500;
}

.baner-bottom__txt {
  font-size: 25px;
  line-height: 35px;
  font-weight: 500;
}

/* @ @ BANER END @ @ */
/* @ @ INFO LIST START @ @ */
.info-list {
  background-color: #E5F3FF;
  padding: 60px;
  border-radius: 25px;
  margin: 0 4vw;
}

.info-list__txt {
  font-size: 17px;
  line-height: 25px;
  padding-left: 60px;
}

.info-list__txt p {
  margin-top: 0;
}

.info-list__wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.info-list__item {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.info-list__item:before {
  content: "";
  background-image: url(../../images/line.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 50%;
  display: block;
  height: 1px;
  position: absolute;
  top: 30px;
  left: 75%;
}

.info-list__item:last-child:before,
.info-list__item:nth-child(4n):before {
  display: none;
}

.info-list__item span {
  display: block;
  text-align: center;
  font-size: 58px;
  line-height: 64px;
  color: #1D70B7;
  margin-bottom: 20px;
}

.info-list__item p {
  border-radius: 25px;
  background-color: #1D70B7;
  color: #fff;
  margin: 0;
  padding: 15px 35px 65px 35px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  font-weight: 500;
}

.info-list__item img {
  position: relative;
  top: -80px;
  margin-bottom: -80px;
  height: 210px;
  -o-object-fit: contain;
  object-fit: contain;
}

/* @ @ INFO LIST END @ @ */
/* @ @ SHORT LIST PATTERN START @ @ */
.short-list__img {
  text-align: center;
}

.short-list__txt {
  display: flex;
  align-items: center;
  position: relative;
}

.short-list__txt:before {
  content: "";
  height: 100%;
  position: absolute;
  top: 40px;
  background-image: url(../../images/blue-pattern.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: -1;
  right: 0;
  left: -240px;
}

.short-list__txt ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 26px;
  line-height: 32px;
  padding-top: 60px;
}

.short-list__txt ul li {
  margin-bottom: 10px;
  color: #fff;
  padding-left: 50px;
  position: relative;
}

.short-list__txt ul li:before {
  content: "";
  height: 22px;
  width: 34px;
  position: absolute;
  display: inline-block;
  left: 0;
  top: 5px;
  background-image: url(../../images/check-white.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
}

/* @ @ SHORT LIST PATTERN END @ @ */
/* @ @ COMPARE LIST START @ @ */
.compare__wrapper {
  overflow: auto;
  padding: 20px;
}

.compare__list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  box-shadow: 0px 0px 10px #1D70B73B;
  border-radius: 15px;
}

.compare__list li {
  padding: 15px 35px;
  font-weight: 500;
}

.compare__list li:nth-child(odd) {
  background-color: rgba(42, 138, 221, 0.05);
}

.compare__icons {
  display: flex;
  padding-left: 10px;
}

.compare__list--funcs {
  flex: 1;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 140px;
}

.compare__list--funcs li {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.compare__title {
  font-size: 12px;
  text-align: center;
  display: block;
  top: 77px;
  background-color: #fff;
  box-shadow: 0px 3px 13px #f4f4f4;
  border-radius: 6px;
  position: absolute;
  visibility: hidden;
  padding: 3px 15px;
}

.compare__top,
.compare__bottom {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.compare__products {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
}

.compare__product {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 140px;
}

.compare__product h3 {
  margin-bottom: 5px;
  margin-top: -5px;
}

.compare__product h3 a {
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  color: #000;
}

.compare__product > .amount,
.compare__product :not(#wph_price_info) .amount {
  color: #3387CE;
  font-size: 14px !important;
  font-weight: 600;
}

.compare__product .wph_price_info {
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
}

.compare__product del {
  opacity: 0.5;
  text-decoration-color: #3387CE;
}

.compare__product ins {
  text-decoration: none;
}

.compare__product #wph_price_info {
  line-height: 14px;
}

.compare__product.ssale h3 {
  margin-bottom: -8px;
}

.compare__product .cbtn {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
  margin-top: 5px;
}

/* @ @ COMPARE LIST END @ @ */
/* @ @ LONG LIST START @ @ */
.info-long {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  padding-top: 100px;
  border-top: 1px solid #DBDBDB;
}

.info-long__chess {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
}

.info-long__chess:nth-child(odd) {
  flex-direction: row-reverse;
}

.info-long__chess-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-long__chess-img img {
  max-height: 700px;
  -o-object-fit: contain;
  object-fit: contain;
}

.info-long__chess-txt {
  display: flex;
  align-items: center;
}

/* @ @ LONG LIST END @ @ */
/* @ @ BOXES START @ @ */
.boxes__title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.boxes__item {
  margin-top: 30px;
}

.boxes__wrapper {
  box-shadow: 0px 0px 30px #1D70B719;
  border-radius: 25px;
  padding: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.boxes__item header {
  display: flex;
  align-items: center;
}

.boxes__item img {
  width: 80px;
  margin-right: 25px;
}

.boxes__item h3 {
  margin: 0;
  font-size: 42px;
  line-height: 46px;
  color: #1D70B7;
}

.boxes__item-txt {
  margin-bottom: 10px;
}

.boxes__item .cbtn-underline {
  margin: auto auto 0 auto;
}

/* @ @ BOXES END @ @ */
/* @ @ CTA START @ @ */
.cta__txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 30px 15px;
}

.cta__txt h2 {
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 30px;
  color: #1D70B7;
}

/* @ @ CTA END @ @ */
/* @ @ FAQ START @ @ */
.faq {
  margin-top: 120px;
  padding-top: 100px;
  border-top: 1px solid #DBDBDB;
}

.faq__txt {
  margin-top: 30px;
}

.faq__item {
  margin-bottom: 20px;
  box-shadow: 0px 0px 20px #0000001F;
  border-radius: 15px;
  padding: 30px 40px 15px 40px;
}

.faq__item-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  padding-bottom: 15px;
  padding-right: 40px;
  position: relative;
  cursor: pointer;
}

.faq__item-title:after {
  content: "";
  width: 44px;
  height: 44px;
  position: absolute;
  right: 0;
  top: -8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(../../images/plus.svg);
  display: inline-block;
}

.faq__item.active .faq__item-title:after {
  background-image: url(../../images/minus.svg);
}

.faq__item-txt {
  font-size: 18px;
  line-height: 24px;
  display: none;
}

.faq__form {
  margin-top: 30px;
}

.cf h2 {
  text-align: center;
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 30px;
}

.cf {
  font-family: "Montserrat", sans-serif;
}

.cf form {
  margin-top: 40px;
}

.cf textarea,
.cf input:not([type=checkbox]):not([type=submit]) {
  resize: none;
  border: none;
  border-bottom: 1px solid #000;
  color: #000;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  padding: 10px 15px;
  margin-bottom: 10px;
  width: 100%;
}

.cf .wpcf7-acceptance .wpcf7-list-item {
  margin-left: 0;
  font-size: 12px;
  margin-bottom: 20px;
}

.cf .wpcf7-acceptance .wpcf7-list-item input[type=checkbox] {
  position: relative;
  top: 2px;
}

.cf input[type=submit] {
  box-shadow: 0px 10px 20px #1D70B763;
  font-family: "Montserrat", sans-serif;
  border-radius: 28px;
  display: block;
  border: none;
  margin: 20px auto;
  background: linear-gradient(263deg, #6AB5F5 0%, #1D70B7 100%);
  transition: 0.3s;
  padding: 12px 35px;
  text-align: center;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  max-width: 100%;
  width: 400px;
}

.cf input[type=submit]:hover {
  box-shadow: 0 0 6px 4px #4d9ee5;
  letter-spacing: 1px;
}

.wpcf7-not-valid-tip {
  font-size: 12px;
}

.faq__form img {
  display: block;
  margin: 20px auto;
}

.page-template-template-contact .faq {
  display: none;
}

.contact-form {
  margin-top: 120px;
}

.contact-form__image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 60px;
}

.contact-datas__txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px 0 15px;
}

.contact-datas__txt div {
  display: flex;
  align-items: flex-start;
  font-size: 22px;
  line-height: 32px;
}

.contact-datas__txt div p {
  margin-top: 0;
}

.contact-datas__txt svg {
  width: 20px;
  height: auto;
  margin-right: 20px;
  position: relative;
}

.contact-datas__txt div svg {
  top: 2px;
}

.contact-datas__txt > a {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}

.contact-datas__txt > a svg {
  top: 7px;
}

/* @ @ FAQ END @ @ */
/* @ @ NEWSLETTER START @ @ */
.newsletter {
  background-color: #E5F3FF;
  padding: 120px 0 60px 0;
  border-radius: 25px;
  display: none !important;
  margin: 120px 4vw 0 4vw;
  position: relative;
}

.newsletter__title {
  margin: 30px 0;
}

.newsletter__form form {
  width: 100%;
  z-index: 9;
  position: relative;
}

.newsletter__form form input[type=email] {
  border: none;
  border-bottom: 1px solid #000;
  color: #000;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  padding: 10px 15px;
  margin-bottom: 10px;
  width: 100%;
  background-color: transparent;
}

.newsletter__checkbox label {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  line-height: 18px;
}

.newsletter__checkbox label input {
  position: relative;
  top: 5px;
  margin-right: 15px;
}

.newsletter__form form input[type=submit] {
  box-shadow: 0px 10px 20px #1D70B763;
  font-family: "Montserrat", sans-serif;
  border-radius: 28px;
  display: block;
  border: none;
  margin: 40px auto 0 auto;
  background: linear-gradient(263deg, #6AB5F5 0%, #1D70B7 100%);
  transition: 0.3s;
  padding: 12px 35px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  max-width: 100%;
  text-align: center;
  width: 400px;
}

.newsletter__form form input[type=submit]:hover {
  box-shadow: 0 0 6px 4px #4d9ee5;
  letter-spacing: 1px;
}

.newsletter__svg-top {
  position: absolute;
  top: -90px;
  left: -4vw;
  max-width: 100%;
  z-index: 1;
}

.newsletter__svg-bottom {
  position: absolute;
  bottom: 40px;
  right: -4vw;
  max-width: 100%;
  z-index: 1;
}

/* @ @ NEWSLETTER END @ @ */
/* @ @ BREADCRUMBS START @ @ */
#breadcrumbs {
  margin-top: 20px;
  color: #8B8B8B;
  font-size: 12px;
}

#breadcrumbs a {
  color: #8B8B8B;
  margin: 0 5px;
}

/* @ @ BREADCRUMBS END @ @ */
/* @ @ CHESS START @ @ */
.chess {
  margin-bottom: 80px;
}

.chess:nth-child(even) {
  flex-direction: row-reverse;
}

.chess__txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.chess__txt h2,
.chess__txt h1 {
  font-size: 40px;
  line-height: 50px;
  color: #1D70B7;
  font-weight: 500;
  margin-bottom: 10px;
}

.chess__txt a {
  color: #1D70B5;
}

.chess__img {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.chess__img .cbtn {
  margin-top: 20px;
}

.chess__img figure {
  text-align: center;
}

.chess__img img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  max-height: 600px;
}

.custom-prods {
  margin-top: 60px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #F4F9FE;
}

.custom-prods .woocommerce .products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 60px;
}

.product-chess {
  float: left;
  margin-top: 60px;
}

.no-padds {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

/* @ @ CHESS END @ @ */
/* @ @ MARKA START @ @ */
.marka-top__txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 30px;
}

.marka-top h1 {
  margin-bottom: 10px;
}

.marka__subtitle {
  display: block;
  margin-bottom: 10px;
  font-size: 28px;
  line-height: 38px;
  font-weight: 500;
}

.marka-top p {
  max-width: 400px;
}

.marka-top .cbtn {
  margin-top: 20px;
}

.marka-bgbaner {
  background-color: #E5F3FF;
  padding: 60px;
  border-radius: 25px;
  margin: 60px 4vw 0 4vw;
}

.marka-bgbaner__img {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 50px;
}

.marka-bgbaner__txt {
  flex: 0 0 75%;
  max-width: 75%;
  padding: 0 40px 0 60px;
  display: flex;
  align-items: center;
}

/* @ @ MARKA END @ @ */
/* @ @ POPUP START @ @ */
.pops {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.pops__close {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.pops__item {
  position: absolute;
  top: 50%;
  padding: 30px;
  left: 50%;
  box-shadow: 0px 0px 3px rgba(183, 183, 183, 0.4);
  transform: translate(-50%, -50%);
  background-color: #1D70B5;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}

.pops__item > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pops__item p {
  margin-top: 0;
  margin-bottom: 10px;
}

.pops__item a {
  margin-top: 10px;
  font-weight: 600;
}

/* @ @ POPUP END @ @ */
/* @ @  START @ @ */
/* @ @  END @ @ */
/* @ @  START @ @ */
/* @ @  END @ @ */
/* @ @  START @ @ */
/* @ @  END @ @ */
/* @ @  START @ @ */
/* @ @  END @ @ */
/* @ @ FOOTER START @ @ */
.footer {
  margin-top: 120px;
  box-shadow: 0px 3px 6px #00000029;
  padding-top: 40px;
}

.footer__menu,
.footer__contact {
  margin-top: 10px;
  margin-bottom: 30px;
}

.footer__logo {
  margin-bottom: 30px;
}

.footer__logo img {
  width: 200px;
}

.footer__menu ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer__menu ul li {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 15px;
  margin-bottom: 10px;
}

.footer__menu ul ul {
  display: none;
}

.footer__contact a {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.footer__contact a svg {
  margin-right: 10px;
  width: 20px;
  height: auto;
}

.footer__top a:hover {
  color: #1D70B7;
}

.footer__bottom {
  background-color: #2A8ADD;
  font-size: 12px;
  color: #fff;
  padding: 10px 0;
}

.footer__bottom a {
  display: inline-block;
  margin: 2px 0;
  color: #fff;
  font-size: 12px;
  text-decoration: underline;
}

.footer__bottom p {
  margin: 2px 0;
  font-size: 12px;
}

.footer__copyright {
  text-align: center;
}

.footer__built {
  text-align: right;
}

/* @ @ FOOTER END @ @ */
/* @ @ WOO START @ @ */
.page-title {
  font-weight: 400;
  text-transform: uppercase;
}

.woocommerce-message,
body .woocommerce-info {
  border-top-color: #2A8ADD;
}

body .woocommerce-message::before,
body .woocommerce-info::before {
  color: #2A8ADD;
}

body.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
body .woocommerce #respond input#submit, body .woocommerce a.button, body .woocommerce button.button, body .woocommerce input.button,
body.woocommerce #respond input#submit, body.woocommerce a.button, body.woocommerce button.button, body.woocommerce input.button {
  border-radius: 25px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 35px !important;
  color: #fff !important;
  background-color: #2A8ADD;
  border: none;
  transition: 0.3s;
  background: linear-gradient(262deg, #82c5ff 0%, #1d70b7 100%);
}

body .woocommerce #payment #place_order, body.woocommerce-page #payment #place_order {
  background-color: #2A8ADD;
}

body .woocommerce #payment #place_order:hover, .woocommerce-page #payment #place_order:hover {
  background-color: #fff;
}

body.woocommerce-cart .wc-proceed-to-checkout a.checkout-button {
  font-size: 22px;
  padding: 15px 35px !important;
}

body.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover,
body .woocommerce #respond input#submit:hover, body .woocommerce a.button:hover, body .woocommerce button.button:hover, body .woocommerce input.button:hover,
body.woocommerce #respond input#submit:hover, body.woocommerce a.button:hover, body.woocommerce button.button:hover, body.woocommerce input.button:hover {
  color: #fff !important;
  letter-spacing: 1px;
  background: linear-gradient(262deg, #82c5ff 0%, #1d70b7 100%) !important;
  box-shadow: 0 0 6px 4px #4d9ee5;
}

.woocommerce-checkout {
  font-size: 16px;
}

.woocommerce-checkout form input,
.woocommerce-checkout form textarea {
  font-family: "Montserrat", sans-serif;
  border: 1px solid #eaeaea;
  font-size: 16px;
}

.woocommerce-product-gallery__image.flex-active-slide {
  pointer-events: none !important;
}

.woocommerce-product-gallery__image.flex-active-slide video {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  max-height: 900px;
}

.onsale-badge {
  position: absolute;
  background-color: #2A8ADD;
  color: #fff;
  font-size: 16px;
  padding: 5px 15px;
  text-transform: uppercase;
  z-index: 9;
}

body ul.products .onsale-badge {
  top: 300px !important;
  left: 10px !important;
  right: initial !important;
}

/* ul.products .button {
    display: none;
} */
.woocommerce ul.products.columns-4 li.product, .woocommerce-page ul.products.columns-4 li.product {
  margin: 0;
  padding: 10px;
  width: 25%;
  margin-top: 15px;
}

.woocommerce ul.products li.product img, .woocommerce-page ul.products li.product img {
  transition: 0.3s;
  margin-bottom: 0 !important;
}

/* .woocommerce ul.products li.product:hover img, .woocommerce-page ul.products li.product:hover img {
    filter: brightness(70%);
} */
.woocommerce ul.products li.product .woocommerce-loop-category__title, .woocommerce ul.products li.product .woocommerce-loop-product__title, .woocommerce ul.products li.product h3 {
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0;
  padding: 0;
}

.woocommerce ul.products li.product .price {
  font-size: 18px;
  font-weight: 400 !important;
  color: #000 !important;
}

/* .woocommerce ul.products li.product .price  ins {
    text-decoration: none;
    font-weight: 400 !important;
} */
.single-product .summary.entry-summary {
  padding-top: 60px;
}

.single-product .summary.entry-summary h1 {
  font-size: 42px;
  line-height: 52px;
  font-weight: 500;
  color: #1D70B7;
  margin-bottom: 20px;
}

.single-product .summary.entry-summary .subtitle {
  display: block;
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: 400;
}

.single-product .woocommerce-product-details__short-description {
  font-size: 16px;
}

.single-product .woocommerce-product-details__short-description a {
  color: #2A8ADD;
  text-decoration: underline;
}

.single-product .summary.entry-summary .price {
  font-size: 28px;
  font-weight: 600 !important;
  /* color: #000 !important; */
  color: #1D70B7 !important;
}

.single-product .summary.entry-summary .price ins {
  text-decoration: none;
}

.single-product .cart,
.woocommerce-variation-add-to-cart {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

body .wrapper-woo div.product form.cart .variations {
  margin: 30px 0;
}

body .wrapper-woo div.product form.cart .variations th {
  text-align: left;
}

body .wrapper-woo div.product form.cart .variations select {
  padding: 10px;
  border-color: #eaeaea;
  border-radius: 13px;
}

/* .single-product .cart .plus,
.woocommerce-variation-add-to-cart .plus {
    border: 1px solid;
    border-left: none;
    background-color: transparent;
    height: 42px;
    font-size: 18px;
    width: 40px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
} */
/* .single-product .cart .minus,
.woocommerce-variation-add-to-cart .minus {
    border: 1px solid;
    border-right: none;
    background-color: transparent;
    height: 42px;
    width: 40px;
    font-size: 18px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.single-product .cart input,
.woocommerce-variation-add-to-cart input {
    height: 42px;
    min-width: 60px;
    font-size: 18px;
} */
/* .woocommerce div.product form.cart .button {
    background-color: #2A8ADD !important;
    height: 48px;
    padding: 10px 45px;
    border-radius: 3px;
    border: 1px solid #2A8ADD;
    margin-left: 30px;
    font-size: 18px;
    color: #fff;
} */
.woocommerce div.product p.stock {
  color: #000;
}

.woocommerce div.product p.out-of-stock {
  color: #DC1F1F;
}

body.single-product form .button {
  padding: 15px 65px 15px 55px !important;
  display: inline-block;
  position: relative;
  color: #fff;
  font-weight: 500;
  box-shadow: 0px 10px 20px #1d70b763;
  border: none;
  font-size: 18px;
  margin-bottom: 20px;
  border-radius: 28px;
  background: linear-gradient(262deg, #82c5ff 0%, #1d70b7 100%) !important;
}

body.single-product form .button:hover {
  box-shadow: 0 0 6px 4px #4d9ee5;
  letter-spacing: 1px;
  color: #fff !important;
}

body.single-product form .button:after {
  content: "";
  box-shadow: 0px 3px 6px #1d70b75e;
  background: url(../../images/cart-white.svg), linear-gradient(180deg, #6AB5F5 0%, #1D70B7 100%);
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  bottom: 0;
  right: -5px;
  transition: 0.3s;
}

body.single-product form .button:hover:after {
  box-shadow: 0 0 6px 4px #4d9ee5;
}

body.single-product form .qib-button.qib-button-wrapper {
  margin-left: 0;
  display: flex;
  margin-right: 20px;
  margin-bottom: 20px;
}

body.single-product form .qib-button.qib-button-wrapper .plus {
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
}

body.single-product form .qib-button.qib-button-wrapper .minus {
  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
}

.wrapper-woo {
  position: relative;
  overflow: hidden;
}

.single-product section.up-sells h2 {
  width: 100%;
  text-align: center;
}

.single-product section.up-sells.products {
  clear: both;
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
}

.single-product section.up-sells.products ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.single-product section.up-sells.products li {
  max-width: 30% !important;
  margin: 20px 1.5% !important;
  width: 30% !important;
  padding-bottom: 30px !important;
}

.single-product section.related.products {
  float: left;
  margin-top: 60px;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
}

.single-product section.related.products:before {
  content: "";
  background-color: #F4F9FE;
  display: inline-block;
  position: absolute;
  left: -100%;
  right: -100%;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.single-product section.related.products ul {
  display: flex !important;
  flex-wrap: wrap !important;
}

.single-product section.related.products li {
  max-width: 30% !important;
  margin: 20px 1.5% !important;
  width: 30% !important;
  padding-bottom: 30px !important;
}

.single-product section.related.products h2 {
  text-align: center;
  font-size: 42px;
  line-height: 52px;
  color: #1D70B7;
}

.single-product section.up-sells.products h2 {
  font-size: 36px;
  text-transform: uppercase;
  padding-left: 10px;
  font-weight: 400;
}

.single-product section.related.products li .my-attr:after {
  left: -10px !important;
  right: -10px !important;
}

.woocommerce-content-wrapper {
  display: flex;
  flex-direction: column;
}

.single-product .shop-link {
  float: left;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.single-product .shop-link:before {
  content: "";
  background-color: #F4F9FE;
  display: inline-block;
  position: absolute;
  left: -100%;
  right: -100%;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.single-product .faq {
  border: none;
  margin-top: 40px;
}

.woocommerce-checkout h3 {
  font-weight: 400;
}

.woocommerce-checkout textarea,
.woocommerce-checkout input {
  padding: 10px;
  font-family: "Titillium Web", sans-serif;
  border-radius: 3px;
  border: 1px solid #000;
}

.woocommerce .woocommerce-result-count {
  display: none;
}

.woocommerce .woocommerce-ordering select {
  padding: 10px;
  border: 1px solid #000;
  border-radius: 3px;
}

.woocommerce div.product div.images .flex-control-thumbs li .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* up sells */
.up-sells {
  padding-left: 15px;
  padding-right: 15px;
}

/* shop, cats page start */
.sidebar {
  box-shadow: 0px 0px 10px #1D70B726;
  border-radius: 15px;
  padding: 30px 30px;
  font-size: 14px;
}

.sidebar .widget:not(:last-child) {
  margin-bottom: 20px;
}

.sidebar .widgettitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.sidebar__close {
  display: none;
}

.sidebar .woocommerce .woocommerce-widget-layered-nav-list li {
  line-height: 20px;
  display: flex;
  margin: 2px 0;
}

.sidebar .woocommerce .woocommerce-widget-layered-nav-list a {
  padding-left: 25px;
  position: relative;
  display: inline-block;
  line-height: 20px;
}

.sidebar .woocommerce .woocommerce-widget-layered-nav-list a::before {
  content: "";
  border: 1px solid #DEDEDE;
  border-radius: 2px;
  width: 12px;
  height: 12px;
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 0;
}

.sidebar .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item--chosen a::before {
  content: "";
  background-color: #1D70B5;
  border-color: transparent;
}

.sidebar .woocommerce .woocommerce-widget-layered-nav-list .count {
  display: none;
}

.woocommerce .sidebar .widget_price_filter .ui-slider-horizontal {
  height: 2px;
  background-color: #eaeaea;
}

.woocommerce .sidebar .widget_price_filter .ui-slider .ui-slider-handle {
  top: -5px;
  background-color: #1D70B5;
}

.woocommerce .sidebar .widget_price_filter .ui-slider .ui-slider-range {
  background-color: #1D70B5;
}

body.single-product .product .onsale,
body .woocommerce ul.products li.product .onsale,
body.woocommerce ul.products li.product .onsale {
  background-color: #1D70B5;
  font-size: 14px;
  color: #fff;
}

.woocommerce ul.products li.product, .woocommerce-page ul.products li.product {
  padding: 5px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 10px #1D70B726;
  border-radius: 15px;
  background-color: #fff;
}

.woocommerce ul.products li.product .attachment-woocommerce_thumbnail, .woocommerce-page ul.products li.product .attachment-woocommerce_thumbnail {
  padding: 15px;
  max-width: 340px;
}

.woocommerce ul.products li.product, .woocommerce ul.products li.product > a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.woocommerce ul.products li.product .woocommerce-loop-category__title, .woocommerce ul.products li.product .woocommerce-loop-product__title, .woocommerce ul.products li.product h3 {
  color: #1D70B7;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}

.woocommerce ul.products li.product ins, .woocommerce ul.products li.product ins, .woocommerce ul.products li.product ins,
.woocommerce ul.products li.product .price, .woocommerce ul.products li.product .price, .woocommerce ul.products li.product .price {
  color: #1D70B7 !important;
  text-decoration: none;
  font-size: 28px;
  line-height: 34px;
  font-weight: 600 !important;
  text-align: center;
}

.products .product-subtitle {
  font-size: 14px;
  font-weight: 600;
  display: block;
  text-align: center;
  margin: 0 0 20px 0;
}

.my-attr {
  border-radius: 15px;
  background-color: #E5F3FF;
  font-size: 14px;
  text-align: center;
  position: relative;
  z-index: 2;
  padding: 10px;
  line-height: 18px;
  margin-bottom: 20px;
}

.my-attr:after {
  content: "";
  background-color: #E5F3FF;
  position: absolute;
  box-shadow: 0px 0px 10px #1D70B726;
  top: 0;
  bottom: 0;
  left: -30px;
  right: -30px;
  z-index: 1;
}

.my-attr > span {
  position: relative;
  z-index: 2;
}

.my-attr__dot {
  display: inline-block;
  margin: 0 5px 4px 5px;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  background-color: #000;
}

body .woocommerce ul.products li.product .add_to_cart_button,
body.woocommerce ul.products li.product .add_to_cart_button {
  padding: 15px 65px 15px 35px !important;
  display: inline-block;
  position: relative;
  color: #fff;
  font-weight: 500;
  box-shadow: 0px 10px 20px #1d70b763;
  border: none;
  font-size: 18px;
  border-radius: 28px;
  background: linear-gradient(262deg, #82c5ff 0%, #1d70b7 100%);
}

body .woocommerce ul.products li.product .add_to_cart_button:hover,
body.woocommerce ul.products li.product .add_to_cart_button:hover {
  box-shadow: 0 0 6px 4px #4d9ee5;
  letter-spacing: 1px;
  color: #fff !important;
}

body .woocommerce ul.products li.product .add_to_cart_button:after,
body.woocommerce ul.products li.product .add_to_cart_button:after {
  content: "";
  box-shadow: 0px 3px 6px #1d70b75e;
  background: url(../../images/cart-white.svg), linear-gradient(180deg, #6AB5F5 0%, #1D70B7 100%);
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  right: -5px;
  transition: 0.3s;
}

body .woocommerce ul.products li.product .add_to_cart_button:hover:after,
body.woocommerce ul.products li.product .add_to_cart_button:hover:after {
  box-shadow: 0 0 6px 4px #4d9ee5;
}

/* shop, cats page end */
.woocommerce-order-received .woocommerce-columns--addresses {
  display: flex;
  flex-wrap: wrap;
}

.woocommerce-order-received .woocommerce-columns--addresses > div {
  float: none !important;
  width: 50% !important;
  margin-top: 30px;
}

#menu-menu-gorne li.menu-item.--menu-item-phone a {
  white-space: nowrap;
  color: red;
}
#menu-menu-gorne li.menu-item.--menu-item-phone a svg {
  height: 1.2em;
  width: auto;
  vertical-align: sub;
}

#archive-info {
  margin-bottom: 30px;
}

#archive-info img {
  padding: 0.5em;
  height: 70px;
  float: left;
}

#archive-info a {
  color: #1D70B7;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .woocommerce .col2-set .col-1,
.woocommerce-page .col2-set .col-1 {
    width: 90%;
  }

  #customer_details {
    display: flex;
    flex-flow: column nowrap;
    float: left;
    width: 50%;
  }

  #customer_details .col-2 {
    width: 90%;
    margin-top: 20px;
  }

  #order_review,
#order_review_heading {
    width: 50%;
    float: left;
    clear: none;
  }
}
/* @ @ WOO END @ @ */
/* @ @ MEDIA START @ @  */
@media (max-width: 1680px) {
  .marka-bgbaner__img {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .marka-bgbaner__txt {
    flex: 0 0 70%;
    max-width: 70%;
  }
}
@media (max-width: 1500px) {
  .baner > img {
    right: 60px;
  }
}
@media (max-width: 1380px) {
  .header__menu > div > ul > li {
    padding-left: 0 !important;
    text-align: center;
  }

  .header__cart a #header__price,
.header__menu > div > ul > li > a {
    font-size: 14px;
  }
}
@media (max-width: 1280px) {
  .baner > img {
    right: 0;
  }

  .info-list__item p {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 18px;
  }

  .single-product section.up-sells.products li,
.single-product section.related.products li {
    max-width: 47% !important;
    margin: 20px 1.5% !important;
    width: 47% !important;
    padding-bottom: 30px !important;
  }

  .marka-bgbaner__img {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    padding: 20px;
  }

  .marka-bgbaner__img img {
    max-width: 400px;
    width: 100%;
  }

  .marka-bgbaner__txt {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 20px;
  }
}
@media (max-width: 1200px) {
  body {
    padding-top: 95px;
  }

  .header__logo {
    width: 180px;
    padding-left: 15px;
  }

  .baner__left,
.baner__right {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 !important;
    margin-bottom: 0;
  }

  .short-list__txt:before {
    left: -140px;
  }

  .short-list__txt ul {
    font-size: 18px;
    line-height: 24px;
  }

  .footer__top,
.footer__top a {
    font-size: 16px !important;
  }
}
@media (min-width: 993px) {
  .header__menu > div > ul > li.menu-item-has-children:hover > ul {
    display: block;
  }

  .header__menu > div > ul > li > ul {
    background-color: #fff;
    position: absolute;
    top: 99%;
    box-shadow: 0px 3px 6px rgba(222, 222, 222, 0.8);
    min-width: 100%;
    width: 240px;
    z-index: 99;
  }

  .header__menu > div > ul > li > ul a {
    font-size: 13px;
    line-height: 18px;
    display: block;
    padding: 7px 10px;
    border-bottom: 1px dotted #eaeaea;
  }

  .header__menu > div > ul > li > ul a:hover {
    color: #fff;
    background-color: #1D70B7;
  }

  .faq__txt {
    padding-right: 60px;
  }

  .faq__form {
    padding-left: 30px;
    padding-right: 40px;
  }

  .newsletter__form form {
    padding-right: 120px;
  }

  .chess:nth-child(odd) .chess__txt {
    padding-right: 60px;
  }

  .chess:nth-child(even) .chess__txt {
    padding-left: 60px;
  }

  .chess:nth-child(even) .chess__img {
    padding-right: 60px;
  }

  .chess:nth-child(odd) .chess__img {
    padding-left: 60px;
  }
}
@media (max-width: 992px) {
  body {
    padding-top: 101px;
  }

  .custom-heading {
    font-size: 38px;
    line-height: 42px;
  }

  .custom-heading br {
    display: none;
  }

  .header__logo img {
    width: 180px;
    transition: 0.3s;
  }

  .scrolled .header__logo img {
    width: 135px;
  }

  .nav-icon {
    display: block;
  }

  .header__menu {
    position: fixed;
    top: 0;
    left: -150vw;
    width: 100%;
    height: 100%;
    overflow: auto;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40px;
    transition: 0.5s;
    background-color: #fff;
    z-index: 999;
  }

  .menuopened .header__menu {
    left: 0;
  }

  .header__menu > div > ul {
    flex-direction: column;
  }

  .header__menu > div > ul > li {
    padding: 0 !important;
    margin: 5px 0;
    text-align: left;
  }

  .header__menu > div > ul > li.menu-item-has-children:after {
    top: 5px;
  }

  .header__menu > div > ul > li > a {
    font-size: 18px;
  }

  .header__menu > div > ul > li.menu-special {
    margin: 5px 0;
  }

  .header__menu > div > ul > li.menu-special > a {
    padding: 5px 15px;
  }

  .header__menu > div > ul > li ul a {
    font-size: 14px;
    display: inline-block;
    margin: 2px 0;
    line-height: 18px;
    border-bottom: 1px dashed #eaeaea;
  }

  #header__counter svg {
    width: 16px;
    height: auto;
  }

  .header__cart {
    width: 60%;
  }

  .header__cart a {
    padding: 11px 20px 4px 20px;
  }

  .header__logo {
    width: initial;
    max-width: 40%;
    flex: 0 0 40%;
  }

  .bar__left {
    width: 100%;
    justify-content: center;
  }

  .bar__right {
    width: 100%;
    margin-top: 20px;
    justify-content: center;
  }

  .baner > img {
    display: none;
  }

  .baner-bottom__title {
    font-size: 42px;
    line-height: 50px;
  }

  .baner-bottom__txt {
    font-size: 22px;
    line-height: 32px;
  }

  .info-list {
    margin: 30px 15px 0 15px;
  }

  .info-list__item:nth-child(2n):before {
    display: none;
  }

  .info-list__txt {
    padding-left: 15px;
    font-size: 16px;
    line-height: 24px;
  }

  .compare__products {
    flex-wrap: wrap;
  }

  .compare__product {
    max-width: 30%;
    width: 100%;
    margin: 20px 1.5%;
    flex: initial;
  }

  .compare__bottom {
    display: none;
  }

  .compare__title {
    display: none !important;
  }

  .short-list__txt {
    margin-top: 30px;
    justify-content: center;
  }

  .short-list__txt:before {
    display: none;
  }

  .short-list__txt ul {
    font-size: 16px;
    background-color: #1D70B7;
    padding: 40px;
    border-radius: 25px;
  }

  .short-list__txt ul li {
    padding-left: 30px;
  }

  .short-list__txt ul li:before {
    height: 15px;
    width: 21px;
  }

  .info-long {
    margin-top: 60px;
    padding-top: 60px;
  }

  .info-long__chess-txt {
    margin: 20px 0;
  }

  .boxes__item h3 {
    font-size: 32px;
    line-height: 36px;
  }

  .cta__txt h2 {
    font-size: 38px;
    line-height: 48px;
  }

  .footer__built,
.footer__privacy {
    text-align: center;
  }

  .chess {
    margin-bottom: 40px;
  }

  .chess__txt h1,
.chess__txt h2 {
    font-size: 32px;
    line-height: 40px;
  }

  .chess__img {
    margin-top: 20px;
  }

  .marka__subtitle {
    font-size: 16px;
    line-height: 24px;
  }

  .marka-bgbaner {
    margin: 60px 15px 0 15px;
  }

  .contact-form {
    margin-top: 60px;
  }

  .contact-form__image {
    margin-top: 40px;
    padding-left: 15px;
    flex-direction: column-reverse;
  }

  .cf {
    order: 0;
  }

  .contact-form__image {
    order: 1;
  }

  .contact-datas__image {
    order: 3;
  }

  .contact-datas__txt {
    order: 2;
    margin-bottom: 30px;
  }

  .woocommerce-order-received .woocommerce-columns--addresses > div {
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  body {
    font-size: 16px;
    line-height: 24px;
    padding-top: 82px;
  }

  .container-full {
    padding-left: 15px;
    padding-right: 15px;
  }

  .marg-top-big {
    margin-top: 60px;
  }

  .custom-heading {
    font-size: 28px;
    line-height: 32px;
  }

  .section-heading {
    font-size: 28px;
    line-height: 32px;
  }

  .header__logo {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header__logo img {
    width: 80%;
    max-width: 160px;
  }

  .bar__left {
    display: none;
  }

  .bar__right {
    margin-top: 0;
  }

  .baner__title {
    font-size: 40px;
    line-height: 50px;
  }

  .baner__subtitle {
    font-size: 20px;
    line-height: 24px;
  }

  .baner-bottom > img {
    left: 0;
    right: 30px;
    top: -60px;
    -o-object-fit: cover;
    object-fit: cover;
    height: 420px;
  }

  .baner-bottom__left img {
    width: 300px;
  }

  .baner-bottom__title {
    font-size: 32px;
    line-height: 42px;
  }

  .baner-bottom__txt {
    font-size: 18px;
    line-height: 26px;
  }

  .info-list {
    padding: 40px 15px;
  }

  .info-list__item:before {
    display: none !important;
  }

  .info-list__item span {
    font-size: 40px;
    line-height: 46px;
  }

  .info-list__item p {
    font-size: 16px;
  }

  .compare__product {
    max-width: 47%;
  }

  .boxes__item img {
    width: 60px;
  }

  .boxes__item h3 {
    font-size: 26px;
    line-height: 32px;
  }

  .cta__txt h2 {
    font-size: 26px;
    line-height: 32px;
  }

  .faq {
    margin-top: 60px;
    padding-top: 40px;
  }

  .faq__item {
    padding-right: 15px;
    padding-left: 15px;
  }

  .faq__item-title {
    font-size: 16px;
    line-height: 24px;
  }

  .newsletter {
    padding: 60px 0;
    margin-top: 40px;
  }

  .footer {
    margin-top: 60px;
  }

  .footer__menu > div > ul li {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
  }

  .sidebar {
    padding: 20px 15px;
  }

  .woocommerce ul.products li.product {
    width: 100% !important;
    margin: 20px 0 !important;
  }

  .single-product section.up-sells.products li,
.single-product section.related.products li {
    max-width: 100% !important;
    margin: 30px 0% !important;
    width: 100% !important;
  }

  .single-product section.up-sells.products h2,
.single-product section.related.products h2 {
    font-size: 26px;
    line-height: 32px;
  }

  .cf textarea,
.cf input {
    font-size: 16px !important;
  }

  .contact-datas__txt {
    padding-left: 30px;
  }

  .marka-bgbaner {
    padding: 20px 0;
  }
}
/* @ @ MEDIA END @ @  */
.woocommerce section#upsell-custom.products.related ul li {
  max-width: 22% !important;
  margin: 20px 1.5% !important;
}
@media (max-width: 1280px) {
  .woocommerce section#upsell-custom.products.related ul li {
    max-width: 30% !important;
  }
}
@media (max-width: 760px) {
  .woocommerce section#upsell-custom.products.related ul li {
    max-width: 47% !important;
  }
}
@media (max-width: 425px) {
  .woocommerce section#upsell-custom.products.related ul li {
    max-width: 100% !important;
  }
}